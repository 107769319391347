export const DEFAULT_COLOR = ['#FAAD14', '#E8EDF3'];

/**
 * 默认配置项
 */
export const DEFAULT_OPTIONS = {
  percent: 0.2,
  color: DEFAULT_COLOR,
  animation: {},
};
